import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { animated } from 'react-spring'
import styled from 'styled-components'
import { fluidRange, lighten } from 'polished'
import Image from './image'
import useAnimation from '../hooks/useAnimation'
import valorem from '../images/valorem.svg'

const HeroContainer = styled(animated.div)`
  width: 100%;
  background-color: ${({ theme }) => theme.color.dark};
  position: relative;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;

  ${fluidRange({
      prop: 'margin-bottom',
      fromSize: '50px',
      toSize: '100px'
    },
    '300px',
    '1600px'
  )}
  ${fluidRange({
      prop: 'height',
      fromSize: '550px',
      toSize: '650px'
    },
    '300px',
    '1600px'
  )}
`

const HeadingContainer = styled.div`
  z-index: 999;
  position: relative;
  text-align: center;
  margin-top: 100px;
  padding: 30px;
`

const Heading = styled.h2`
  color: ${({ theme }) => lighten(0.1, theme.color.primary)};
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.1em;
  font-size: 32px;
  margin: 0;
  line-height: 1.5;

  ${fluidRange({
      prop: 'font-size',
      fromSize: '18px',
      toSize: '32px'
    },
    '350px',
    '650px'
  )}
`

const Valorem = styled.img`
  max-width: 100%;
  height: auto;
  padding: 0 0 10px;
  margin: 0;
`

const Background = styled(animated(Image))`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 80%;
  ${fluidRange({
      prop: 'height',
      fromSize: '650px',
      toSize: '750px'
    },
    '300px',
    '1600px'
  )}

  @media(max-width: ${({ theme }) => theme.breakpoint.large}) {
    width: 90%;
  }

  @media(max-width: ${({ theme }) => theme.breakpoint.medium}) {
    width: 100%;
  }
`

const Hero = (props) => {
  const { fadeIn } = useAnimation()

  const { background } = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "hero-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1767, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <HeroContainer style={fadeIn}>
      <HeadingContainer>
        <Valorem src={valorem} alt='Valorem Distribution' />
        <Heading>Beauty without boundaries</Heading>
      </HeadingContainer>
      <Background
        fluid={background.childImageSharp.fluid}
        notch='hero'
        background
      />
    </HeroContainer>
  )
}

export default Hero
