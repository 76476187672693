import React from 'react'
import Link from '../components/link'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/seo'
import Title from '../components/title'
import Header from '../components/header'
import Hero from '../components/hero'
import BrandLogos from '../components/brand-logos'
import Container from '../components/container'
import FeatureBlock from '../components/feature-block'
import Button from '../components/button'
import Layout from '../layout'
import Image from '../components/image'
import P from '../components/paragraph'
import AnimatedArticle from '../components/animated-article'
import AnimatedAside from '../components/animated-aside'

const IndexPage = () => {
  const { bottles, kuwait, bottleBlack } = useStaticQuery(graphql`
    query {
      bottles: file(relativePath: { eq: "brands/bottle.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kuwait: file(relativePath: { eq: "events/kuwait.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bottleBlack: file(
        relativePath: { eq: "nod.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={`
          Valorem Distribution: Beauty without boundaries for niche perfume
          brands
        `}
        description={`
          Valorem is a celebration of beauty without boundaries; a distinctively
          individual approach, totally in tune with today's ambitious niche
          perfume brands.
        `}
      />

      <Header dark absolute />
      <Hero />

      <Container wide>
        <FeatureBlock>
          <AnimatedAside right>
            <Image
              css={`
                @media (min-width: ${({ theme }) => theme.breakpoint.large}) {
                  margin-top: 100px;
                }
              `}
              fluid={bottles.childImageSharp.fluid}
              notch
              alt='Valorem Distribution Services'
            />
          </AnimatedAside>
          <AnimatedArticle>
            <Title fancy>Mission</Title>
            <P>
              There are delivery services, and there&apos;s Valorem. Valorem is
              a celebration of beauty without boundaries; a distinctively
              individual approach, totally in tune with today&apos;s ambitious
              niche brands. Integrity, commitment & an &apos;anything is
              possible&apos; approach lie behind the success of Valorem. We
              build trusted relationships for the long term, relentlessly seek
              new opportunities for our clients and act with commitment, agility
              and speed.
            </P>
            <P>
              Welcome to the distribution service as audacious as your brand.
            </P>
            <P spaced>
              <Link to='/about' arrow>
                Read more about us
              </Link>
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>

      <Container>
        <Title center from='20px' to='30px'>
          <h4>Our brands</h4>
        </Title>
        <BrandLogos />
        <center>
          <Button to='/brands'>View all brands</Button>
        </center>
      </Container>

      <Container wide noPadding='top'>
        <FeatureBlock center grey={false}>
          <AnimatedArticle>
            <P subTitle small noMargin>
              19th May - 4th June 2022
            </P>
            <Title from='20px' to='22px' tight>
              Perfumery and Cosmetic Exhibition
            </Title>
            <P small noMargin>
              KIF – Kuwait.
            </P>
            <P>
              Taking place over 10 days, twice a year, it is one of the major
              events in Kuwait. With the blend of ancient oiled scents from the
              Arabian Gulf to modern cosmetic brands and everything else
              in-between. Over 100 brands participate in the event and people
              will travel from all over the gulf to attend, discover and
              purchase new and bestselling products. Working with our partners,
              VDL have participated in this event for the past two years,
              occupying two stands.
            </P>
            <P spaced>
              <Link to='/events' arrow>
                View all events
              </Link>
            </P>
          </AnimatedArticle>
          <AnimatedAside>
            <Image
              fluid={kuwait.childImageSharp.fluid}
              notch='topLeft'
              alt='Valorem Distribution Services Kuwait Event'
            />
          </AnimatedAside>
        </FeatureBlock>
      </Container>

      <Container wide noPadding='top'>
        <FeatureBlock center>
          <AnimatedAside right>
            <Image
              fluid={bottleBlack.childImageSharp.fluid}
              notch='topRightSmall'
              alt='Christian Provenzano perfume bottle'
            />
          </AnimatedAside>
          <AnimatedArticle>
            <Title fancy>Bespoke</Title>
            <P>
              Valorem has an obsession for creativity – not only a desire to
              engage existing brands, but to assist in establishing new ones.
              From product creation, packaging, marketing, communications and on
              to launch. Valorem has a finite understanding of what it is to be
              different; how to make this a commercial advantage.
            </P>
            <P spaced>
              <Link to='/bespoke' arrow>
                Read more about bespoke
              </Link>
            </P>
          </AnimatedArticle>
        </FeatureBlock>
      </Container>
    </Layout>
  )
}

export default IndexPage
