import React, { useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { useTrail, animated } from 'react-spring'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import useIsInViewport from 'use-is-in-viewport'
import useTrigger from '../hooks/useTrigger'

const AnimatedImg = animated(Img)

const BrandsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  ${fluidRange(
    {
      prop: 'margin-top',
      fromSize: '30px',
      toSize: '60px',
    },
    '300px',
    '1600px'
  )}
  ${fluidRange(
    {
      prop: 'margin-bottom',
      fromSize: '30px',
      toSize: '60px',
    },
    '300px',
    '1600px'
  )}
`

const Brand = styled.div`
  width: 20%;
  margin-left: 5%;
  margin-right: 5%;
  ${fluidRange(
    {
      prop: 'margin-top',
      fromSize: '0.5%',
      toSize: '1%',
    },
    '300px',
    '1600px'
  )}
  ${fluidRange(
    {
      prop: 'margin-bottom',
      fromSize: '0.5%',
      toSize: '1%',
    },
    '300px',
    '1600px'
  )}
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoint.medium}) {
    width: 40%;
  }
`

const BrandLogos = () => {
  const containerRef = useRef(null)
  const [isInViewport, wrappedContainerRef] = useIsInViewport({
    target: containerRef,
    threshold: 40,
  })
  const trigger = useTrigger(isInViewport)

  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "brands/logos" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 222, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const fadeIn = useTrail(allFile.edges.length, {
    opacity: trigger ? 1 : 0,
    x: trigger ? 0 : 20,
    height: trigger ? 80 : 0,
  })

  return (
    <BrandsContainer ref={wrappedContainerRef}>
      {fadeIn.map(({ x, height, ...rest }, index) => (
        <Brand key={index}>
          <AnimatedImg
            fluid={allFile.edges[index].node.childImageSharp.fluid}
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
            }}
          />
        </Brand>
      ))}
    </BrandsContainer>
  )
}

export default BrandLogos
